import { getCustomerToken } from "@commercelayer/js-auth"
import { Customer } from "@commercelayer/sdk"
import axios from "axios"

const isBrowser = typeof window !== `undefined`

export const getUser = () =>
  isBrowser && window.localStorage.User
    ? JSON.parse(window.localStorage.User)
    : {}

const connectionConfig = {
  clientId: "0z-cq5losROWDZ7PV8X-jNjjUz-ipHAXeQlop3LdTb0",
  endpoint: "https://dkb.commercelayer.io",
  scope: "market:8033",
}

const setUser = user => (window.localStorage.User = JSON.stringify(user))


// export const refreshToken = async () => {
//   var axios = require("axios")
//   const { refreshToken, username } = getUser()
//   var data = JSON.stringify({
//     grant_type: "refresh_token",
//     refresh_token: refreshToken,
//     client_id: connectionConfig.clientId,
//   })

//   var config = {
//     method: "post",
//     url: connectionConfig.endpoint+"/oauth/token",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     data: data,
//   }

//   return await axios(config)
//     .then(function (response) {
//       const data = response.data;
//       setUser({
//         accessToken: data.accessToken,
//         tokenExpiration: data.expires,
//         refreshToken: data.refresh_token,
//         username,
//         ownerId: data.owner_id,
//         scope: data.scope,
//       })
//     })
//     .catch(function (error) {
//       console.log(error)
//     })

// }

export const handleLogin = async ({ username, password }) => {
  if (!isBrowser) return false

  const token = await getCustomerToken(connectionConfig, {
    username,
    password,
  })

  if (token) {
    setUser({
      accessToken: token.accessToken,
      tokenExpiration: token.expires,
      refreshToken: token.refreshToken,
      username: token.client.options.username,
      ownerId: token.data.owner_id,
      scope: token.data.scope,
    })

    return {
      accessToken: token.accessToken,
      tokenExpiration: token.expires,
      refreshToken: token.refreshToken,
      username: token.client.options.username,
      ownerId: token.data.owner_id,
      scope: token.data.scope,
    }
  }

  return false
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return

  setUser({})
  callback()
}
