/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Header from "./header"
import { Box, Flex } from "theme-ui"

const GlobalLayout = ({ children }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      <Header siteTitle="B2B | DKB Sport" />
      <Box sx={{ paddingTop: "10rem" }}>{children}</Box>
      <Box
        as="footer"
        sx={{ textAlign: "center", fontSize: 0, py: 3, color: "#999" }}
      >
        Punto Azzurro S.r.l. - via Vogno, 20 24020 Rovetta (BG) T.
        +39.0346.74187 help@dkbsport.com
      </Box>
    </Flex>
  )
}

export default GlobalLayout
