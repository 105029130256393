import React, { useEffect } from "react"
import { Link } from "gatsby"
import { Flex, Box, Container, Image } from "theme-ui"
import { useAuth } from "../hooks/useAuth"
import { getCurrentUser, logout } from "../utils/auth"
import { navigate } from "gatsby"
import logo from "../images/dkb-logo.png"

export default function NavBar() {
  const { token, setToken, cartCounter, setCartCounter } = useAuth()

  return (
    <Box
      as="nav"
      variant="links"
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 2px -1px",
        position: "fixed",
        width: "100%",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: "text",
          color: "white",
          borderBottom: "4px solid",
          borderColor: "primary",
          textAlign: "center",
          fontSize: 0,
          paddingY: 2,
          textTransform: "uppercase",
        }}
      >
        Make your mark
      </Box>
      <Container sx={{ paddingY: 3 }}>
        <Flex
          sx={{
            flexDirection: "row",
            padding: 0,
            margin: 0,
            alignItems: "center",
            listStyle: "none",
            justifyContent: "space-between",
          }}
        >
          <Link to="/dashboard">
            <Image src={logo} />
          </Link>
          {token && (
            <>
              <Flex
                sx={{
                  flexDirection: "row",
                  a: {
                    textDecoration: "none",
                    "&:hover": {
                      color: "primary",
                    },
                  },
                }}
              >
                <Box as="li" sx={{ paddingX: 3, paddingY: 3 }}>
                  <Link to="/dashboard">Prodotti</Link>
                </Box>
                <Box as="li" sx={{ paddingX: 3, paddingY: 3 }}>
                  <Link to="/dashboard/ordini">Ordini</Link>
                </Box>
                <Box as="li" sx={{ paddingX: 3, paddingY: 3 }}>
                  <Flex>
                    <Link to="/dashboard/carrello">
                      Carrello{" "}
                      <Box
                        as="span"
                        sx={{
                          backgroundColor: "primary",
                          padding: 1,
                          color: "white",
                          borderRadius: "full",
                          fontSize: 0,
                          width: "24px",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                      >
                        {cartCounter}
                      </Box>
                    </Link>
                  </Flex>
                </Box>
              </Flex>
              <Box>
                <Box as="li" sx={{ paddingX: 3, paddingY: 3 }}>
                  <a
                    href="/"
                    onClick={event => {
                      event.preventDefault()
                      logout(() => {
                        setToken(null)
                        navigate(`/`)
                      })
                    }}
                  >
                    Esci
                  </a>
                </Box>
              </Box>
            </>
          )}
        </Flex>
      </Container>
    </Box>
  )
}
